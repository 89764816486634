<template>
  <v-main>
    <slot />
  </v-main>
</template>

<script
  setup
  lang="ts"
>
</script>
